import { useState, useEffect } from "react";
import { csv, autoType } from "d3";

const useData = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const csvUrl = `${process.env.PUBLIC_URL}/data/Data.csv`; // Adjust the path as needed
        const fetchedData = await csv(csvUrl, autoType);
        setData(fetchedData);
        setLoading(false);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading, error };
};

export default useData;
