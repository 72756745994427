import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, useMap, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import * as d3 from "d3";
import "./FullPageMap.css";
import useData from "./useData"; // Import the useData hook

const THRESHOLDS = 10;
const MAX_VAL = 20;
const MIN_VAL = 0;

const SetViewOnClick = ({ coords }) => {
  const map = useMap();
  map.setView(coords, 14); // You can adjust the zoom level here
  return null;
};

const D3Overlay = () => {
  const map = useMap();
  const svgRef = useRef(null);
  const legendRef = useRef(null);
  const { data, loading, error } = useData(); // Use the data hook
  const [colorScale, setColorScale] = useState(null);

  const drawLegend = (thresholds, color) => {
    if (loading || error) return; // Don't draw anything if loading or error

    console.log("creating legend");

    const svg = d3
      .select(legendRef.current)
      .selectAll("svg")
      .data([null])
      .join("svg")
      .style("overflow", "visible");

    const columnWidth = 100; // Adjust as needed

    const numberOfRows = Math.ceil(thresholds.length / 2);

    const legend = svg
      .selectAll(".legend")
      .data(thresholds)
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr("transform", (_, i) => {
        const x = i >= numberOfRows ? columnWidth : 0;
        const y = (i % numberOfRows) * 20;
        return `translate(${x},${y})`;
      });

    legend
      .append("rect")
      .attr("x", 0)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", (d) => color(d));

    legend
      .append("text")
      .attr("x", 24)
      .attr("y", 9)
      .attr("dy", ".35em")
      .style("text-anchor", "start")
      .text((d) => {
        return d;
      });
  };

  // useEffect(() => {
  //   drawLegend();
  // }, [colorScale]);

  //This function runs through the data and snaps each point to the closest grid point, overwriting the previous value if there's such.
  //each datapoint is an array of 3 values, [x, y, z]. The x and y values are the coordinates of the point, and the z value is the value of the point.
  //The grid point needs to be updated with the z value of the point.
  const createGrid = (width, height, dataPoints) => {
    //read the current zoom level of the map
    const zoom = +map.getZoom();

    // console.log("zoom: ", zoom);

    const qScale = d3.scaleLinear().domain([14, 18]).range([12, 36]);

    qScale.clamp(true);

    // console.log("q: ", Math.floor(qScale(zoom)));

    const q = Math.floor(qScale(zoom)); // The level of detail, sample every 10 pixels in x and y.
    const x0 = -q / 2,
      x1 = width + 28 + q;
    const y0 = -q / 2,
      y1 = height + q;
    const n = Math.ceil((x1 - x0) / q);
    const m = Math.ceil((y1 - y0) / q);

    const grid = new Array(n * m);
    for (let j = 0; j < m; ++j) {
      for (let i = 0; i < n; ++i) {
        // grid[j * n + i] = Math.random() * i + j;
        grid[j * n + i] = 0;
      }
    }

    //run through the data and snap each point to the closest grid point, overwriting the previous value if there's such.
    //each datapoint is an array of 3 values, [x, y, z]. The x and y values are the coordinates of the point, and the z value is the value of the point.
    //The grid point needs to be updated with the z value of the point.
    dataPoints.forEach((d) => {
      let x = Math.floor(d[0] / q);
      let y = Math.floor(d[1] / q);
      let z = d[2];
      grid[y * n + x] = z;
    });

    grid.x = -q;
    grid.y = -q;
    grid.k = q;
    grid.n = n;
    grid.m = m;
    return grid;
  };

  const redraw = () => {
    // console.log("redraw");
    if (loading || error) return; // Don't draw anything if loading or error

    const bounds = map.getBounds();
    const width = svgRef.current.clientWidth;
    const height = svgRef.current.clientHeight;

    const northWest = bounds.getNorthWest();
    const southEast = bounds.getSouthEast();

    const projection = d3
      .geoMercator()
      .center([
        (northWest.lng + southEast.lng) / 2,
        (northWest.lat + southEast.lat) / 2,
      ])
      .scale(((width / (southEast.lng - northWest.lng)) * 360) / (2 * Math.PI))
      .translate([width / 2, height / 2]);

    const svg = d3.select(svgRef.current);

    //clear existing circles
    svg.selectAll("circle").remove();

    // let dataSlice = data; //.filter((d, i) => i % 50 === 0);
    let dataSlice = data.filter((d) => {
      //remove the points that are outside the current map bounds
      let x = Math.floor(projection([d.lng, d.lat])[0].toFixed(0));
      let y = Math.floor(projection([d.lng, d.lat])[1].toFixed(0));
      return x >= 0 && x <= width && y >= 0 && y <= height;
    });

    let dataMapped = dataSlice.map((d) => {
      let retval = [
        Math.floor(projection([d.lng, d.lat])[0].toFixed(0)),
        Math.floor(projection([d.lng, d.lat])[1].toFixed(0)),
        d.value,
      ];
      return retval;
    });

    //calculate duration of following function
    // console.log("starting: ", new Date().getTime());

    const grid = createGrid(width, height, dataMapped);

    // const extent = d3.extent(dataMapped, (d) => d[2]);

    const fixedExtent = [-10, 30];

    //create an array of size THRESHOLDS, with values from extent[0] to extent[1]
    const thresholds = d3.range(
      fixedExtent[0],
      fixedExtent[1],
      (fixedExtent[1] - fixedExtent[0]) / THRESHOLDS
      // extent[0],
      // extent[1],
      // (extent[1] - extent[0]) / THRESHOLDS
    );

    const transform = ({ type, value, coordinates }) => {
      return {
        type,
        value,
        coordinates: coordinates.map((rings) => {
          return rings.map((points) => {
            return points.map(([x, y]) => [
              grid.x + grid.k * x,
              grid.y + grid.k * y,
            ]);
          });
        }),
      };
    };

    const contours = d3
      .contours()
      .size([grid.n, grid.m])
      .thresholds(thresholds)(grid)
      .map(transform);

    console.log("contours: ", contours);

    const opacityScale = d3
      .scaleLinear()
      .domain([0, d3.max(thresholds, (d) => Math.abs(d))])
      .range([0, 1]);

    //create a sequential scale for the contours colors, based on the interpolateMagme color scheme, and the min and max values of the contours
    const color = d3
      .scaleSequential()
      .domain(d3.extent(contours, (d) => d.value))
      .interpolator(d3.interpolateMagma);

    // setColorScale(color);

    drawLegend(thresholds, color);

    svg
      .selectAll("path.contour")
      .data(contours)
      .join("path")
      .attr("class", "contour")
      .attr("d", d3.geoPath())
      .attr("fill", (d) => color(d.value))
      .attr("opacity", (d) => opacityScale(Math.abs(d.value)))
      .attr("stroke", "black");
  };

  useEffect(() => {
    redraw();
  }, [map, data, loading, error]);

  useMapEvents({
    moveend: () => {
      redraw();
    },
    zoomend: () => {
      redraw();
    },
    movestart: () => {
      d3.select(svgRef.current).selectAll("path").remove();
    },
    zoomstart: () => {
      d3.select(svgRef.current).selectAll("path").remove();
    },
  });

  return (
    <div>
      <svg ref={svgRef} className="full-page-overlay"></svg>
      <div ref={legendRef} className="legend-container">
        <div className="legend-title">Control Bar</div> <svg />
      </div>
    </div>
  );
};
const FullPageMap = () => {
  const coords = [-28.13, -69.63];

  return (
    <div className="container">
      <MapContainer
        className="full-page-map"
        center={coords}
        zoom={8}
        scrollWheelZoom={true}
      >
        <SetViewOnClick coords={coords} />

        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
          opacity={0.5}
        />
        <D3Overlay />
      </MapContainer>
    </div>
  );
};

export default FullPageMap;
